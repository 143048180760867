<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <button @click="connect">connect</button>
  </div>
</template>

<script>
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
// import { ethers } from 'ethers'

const MAINNET_RPC_URL = 'https://bsc-dataseed.binance.org'
const injected = injectedModule()
const walletConnect = walletConnectModule({
  qrcodeModalOptions: {
    mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar']
  },
  connectFirstChainId: true
})
const onboard = Onboard({
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: true,
      minimal: true
    }
  },
  connect: {
    showSidebar: false
  },
  wallets: [injected, walletConnect],
  chains: [
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain',
      rpcUrl: MAINNET_RPC_URL
    }
  ]
})

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    connect : async ()=>{
      const wallets = await onboard.connectWallet()
      console.log(wallets)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
