if (window.ethereum) {
    handleEthereum();
} else {
    window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
    });
    // If the event is not dispatched by the end of the timeout,
    // the user probably doesn't have MetaMask installed.
    setTimeout(handleEthereum, 20000); //5 seconds
}

function handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
        console.log('Ethereum successfully detected!');
        alert("Provider detected!")
        console.log(ethereum)
    } else {
        console.log('Please install MetaMask!');
        alert("not installed!")
    }
}

import Vue from 'vue'
import App from './App.vue'

new Vue({
    // router,
    // store,
    render: h => h(App)
}).$mount('#app')
